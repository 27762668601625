import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import SEO from "components/seo"
import { StateContext } from "components/Context"
import TransitionWrapper from "components/TransitionWrapper"
import Container from "components/Container"
import Banner from "components/Banner"
import { AboutTimeline, FooterTimeline } from "utils/Animations"

const AboutPage = ({ data, transitionStatus }) => {
  const {
    state: { sparks, isLoaded, aboutTl },
    dispatch,
  } = useContext(StateContext)

  // CREATE ABOUT TIMELINE
  useEffect(() => {
    if (!sparks || aboutTl.totalDuration() > 0) return
    aboutTl.add(AboutTimeline(sparks))
    aboutTl.add(FooterTimeline())
    dispatch({
      type: "SET_CURRENT_TIMELINE_DURATION",
      value: aboutTl.totalDuration(),
    })

    window.aboutTl = aboutTl
  }, [sparks, data.contentfulPageAbout.sections, dispatch, aboutTl])

  const onEntering = () => {
    aboutTl.timeScale(1).play()
  }

  const onEntered = () => {
    if (window.locoscroll) {
      window.locoscroll.start()
      window.locoscroll.update()
    }
    if (!isLoaded) return
    aboutTl.timeScale(1).play()
  }

  const onExiting = () => {
    aboutTl
      .timeScale(5)
      .reverse()
      .then(tl => tl.clear(true))
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span className="bold">{text}</span>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="underline"
          title={children}
          href={node.data.uri}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="pb-4">{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="pr-4" data-anim-line="">
          {children}
        </p>
      ),
    },
  }

  return (
    <>
      <SEO lang={"en"} pageTitle={data.contentfulPageAbout.title} />

      <TransitionWrapper
        page="about"
        isLoaded={isLoaded}
        transitionStatus={transitionStatus}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
      >
        <div className="pt-24 md:pt-48 md:pb-24">
          <h1 className="fixed font-primary-bold inset-0 flex items-center leading-none justify-center text-center uppercase text-gray-700 opacity-0 text-6xl md:text-10xl pointer-events-none -z-1">
            {data.contentfulPageAbout.title}
          </h1>
          <Banner firstWord="About" secondWord="Me" />
          {data.contentfulPageAbout.image && (
            <div
              className="c-image relative md:-mt-40 ml-6 sm:ml-24 md:ml-64 w-2/5 md:w-1/3 max-w-xs transition-opacity duration-1000 delay-100 ease-out"
              data-scroll=""
              data-scroll-direction="vertical"
              data-scroll-speed="-1.1"
            >
              <img
                src={data.contentfulPageAbout.image.fixed.src}
                alt={data.contentfulPageAbout.image.title}
              />
            </div>
          )}
          <Container>
            <div className="flex flex-wrap md:mt-10">
              {data.contentfulPageAbout.sections.map((section, index) => {
                return (
                  <section
                    key={section.id}
                    className={`w-full pt-6 lg:w-6/12 mt-3`}
                    data-anim-section=""
                    data-scroll=""
                    // data-scroll-speed={index > 0 ? "5" : 0}
                  >
                    <h3
                      className="u-outline text-3xl md:text-5xl inline transition duration-500 ease-in-out"
                      data-anim-title=""
                      data-effect-text={index}
                    >
                      {section.title}
                    </h3>
                    <img
                      className="absolute opacity-0 pointer-events-none -z-1"
                      src={section.image}
                      alt={section.title}
                      data-effect-image={index}
                    />
                    <div className="font-secondary">
                      {renderRichText(section.description, options)}
                    </div>
                  </section>
                )
              })}
            </div>
          </Container>
        </div>
      </TransitionWrapper>
    </>
  )
}

export default AboutPage

export const query = graphql`
  query AboutPageQuery {
    contentfulPageAbout(node_locale: { eq: "en" }) {
      title
      image {
        title
        fixed(resizingBehavior: SCALE, quality: 90, width: 400, toFormat: JPG) {
          src
        }
      }
      sections {
        id
        title
        description {
          raw
        }
        image
      }
    }
  }
`
